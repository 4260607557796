import $axios from '~/plugins/axios';
import taskTypes from './task-types';
import { Api, Admin, TaskItem, TaskItemComment, TaskItemLight, GetTaskItemParams, GetTaskItemMeParams, TaskLight, GetAdminParams, GetTaskItemStatisticsParams, GetTaskItemMeStatisticsParams, TaskItemStat } from '~/api';
import { buildModule, createFiltersModule, createPaginationModule, defineSingleFilter } from '../utils';
import { t } from 'i18next';

const api = $axios as Api;

export default buildModule()
	.withSubmodules({
		taskTypes,
		filters: createFiltersModule({
			filters: {
				typeId: defineSingleFilter('type', Number),
				statusId: defineSingleFilter('status', Number),
				state: defineSingleFilter('state', String),
				assigned: defineSingleFilter('assigned', String),
				showAll: defineSingleFilter('showAll', Boolean, false),
				orderBy: defineSingleFilter('orderBy', String),
				orderAsc: defineSingleFilter('orderAsc', Boolean, false),
			},
		}),
		pagination: createPaginationModule({
			pageQueryKey: 'page',
			pageSizeQueryKey: 'itemsPerPage',
			itemCountGetter: 'tasks/tasksTotalCount',
		}),
	})

	.withState({
		tasks: [] as TaskLight[],
		taskItems: [] as TaskItemLight[],
		taskItemsStats: [] as TaskItemStat[],
		taskItem: null as TaskItem | null,
		mineTaskItems: [] as TaskItemLight[],
		tasksTotalCount: 0,
		assignableAdmins: [] as Admin[],
		taskItemComments: [] as TaskItemComment[],
		taskItemCommentsTotalCount: 0,
	})

	.withGetters({
		tasks: (state) => state.tasks,
		taskItems: (state) => state.taskItems,
		taskItemsStats: (state) => state.taskItemsStats,
		taskItem: (state) => state.taskItem,
		tasksTotalCount: (state) => state.tasksTotalCount ?? 0,
		assignableAdmins: (state) => state.assignableAdmins,
		taskItemComments: (state) => state.taskItemComments,
		taskItemCommentsTotalCount: (state) => state.taskItemCommentsTotalCount ?? 0,
	})
	.withGetters({
		taskItemCommentsRemainingCount: (state, getters) => getters.taskItemCommentsTotalCount - state.taskItemComments.length,
	})

	.withMutations({
		SET_TASKS (state, payload: TaskLight[]) {
			state.tasks = payload;
		},
		SET_TASKS_TOTAL_COUNT (state, payload: number) {
			state.tasksTotalCount = payload;
		},
		SET_TASK_ITEM (state, payload: TaskItem | null) {
			state.taskItem = payload;
		},
		SET_TASK_ITEMS (state, payload: TaskItemLight[]) {
			state.taskItems = payload;
		},
		SET_TASK_ITEMS_STATS (state, payload: TaskItemStat[]) {
			state.taskItemsStats = payload;
		},
		SET_ASSIGNABLE_ADMINS (state, payload: Admin[]) {
			state.assignableAdmins = payload;
		},
		SET_TASK_ITEM_COMMENTS (state, payload: TaskItemComment[]) {
			state.taskItemComments = payload;
		},
		SET_TASK_ITEM_COMMENTS_COUNT (state, payload: number) {
			state.taskItemCommentsTotalCount = payload;
		},
	})

	.withActions({
		async getTaskItems ({ commit }, { showAll, ...params }: (GetTaskItemParams | GetTaskItemMeParams) & { showAll: boolean }) {
			try {
				const resp = showAll
					? await api.get('/task-item', { params })
					: await api.get('/task-item/me', { params });
				commit('SET_TASK_ITEMS', resp.data.payload);
				commit('SET_TASKS_TOTAL_COUNT', resp.data.paginator.totalCount);
				return resp;
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Seznam úkolů se nepodařilo načíst.') }</p>`,
					type: 'error',
				});
			}
		},

		async getTaskItemsStats ({ commit }, { showAll, ...params }: (GetTaskItemStatisticsParams | GetTaskItemMeStatisticsParams) & { showAll: boolean }) {
			try {
				const resp = showAll
					? await api.get('/task-item/statistics', { params })
					: await api.get('/task-item/me/statistics', { params });
				commit('SET_TASK_ITEMS_STATS', resp.data.payload);
				return resp;
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Statistiky úkolů se nepodařilo načíst.') }</p>`,
					type: 'error',
				});
			}
		},

		async getTaskItem ({ commit }, id: number) {
			try {
				const resp = await api.get(`/task-item/${ id }`);
				commit('SET_TASK_ITEM', resp.data.payload);
				return resp;
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Detail úkolu se nepodařilo načíst.') }</p>`,
					type: 'error',
				});
			}
		},

		async assignTask (_, { id, adminId }: { id: number; adminId: number }) {
			try {
				const resp = await api.put(`/task-item/${ id }/assign`, { adminId });
				new TempMessage({
					html: `<p>${ t('Úkol %taskName% byl přiřazen uživateli %adminName%.', { taskName: resp.data.payload.task.name, adminName: resp.data.payload.admin?.personalData.fullname }) }</p>`,
					type: 'success',
				});
				return resp;
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Úkol se nepodařilo přiřadit.') }</p>`,
					type: 'error',
				});
			}
		},

		async unassignTask (_, id: number) {
			try {
				const resp = await api.put(`/task-item/${ id }/un-assign`);
				new TempMessage({
					html: `<p>${ t('Úkol %taskName% byl změněn na nepřiřazený.', { taskName: resp.data.payload.task.name }) }</p>`,
					type: 'success',
				});
				return resp;
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Přiřazení úkolu se nepodařilo odstranit.') }</p>`,
					type: 'error',
				});
			}
		},

		async changeTaskStatus (_, { id, status }: { id: number; status: number }) {
			try {
				const resp = await api.put(`/task-item/${ id }`, { status });
				return resp;
			}
			catch (err) {
				return Promise.reject(err);
			}
		},

		async changeTaskInfoValue (_, { id, taskInfoId, value }: { id: number; taskInfoId: number; value: string }) {
			try {
				const resp = await api.put(`/task-item/${ id }/task-info/${ taskInfoId }`, {
					value,
					valueId: null,
				});
				return resp;
			}
			catch (err) {
				return Promise.reject(err);
			}
		},

		async deleteTaskInfoValue (_, { id, taskInfoId }: { id: number; taskInfoId: number }) {
			try {
				const resp = await api.delete(`/task-item/${ id }/task-info/${ taskInfoId }`);
				console.log(resp);
				return resp;
			}
			catch (err) {
				return Promise.reject(err);
			}
		},

		async getAssignableAdmins ({ commit }, { params }: { params: GetAdminParams }) {
			try {
				const resp = await api.get('/admin', { params });
				commit('SET_ASSIGNABLE_ADMINS', resp.data.payload);
				return resp;
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Seznam uživatelů se nepodařilo načíst.') }</p>`,
					type: 'error',
				});
			}
		},

		async getComments ({ commit, state }, { id, limit = 5, loadMore = false }:{ id: number; limit?: number; loadMore?: boolean }) {
			try {
				const resp = await api.get(`/task-item/${ id }/comment`, {
					params: {
						offset: loadMore ? state.taskItemComments.length : 0,
						limit,
					},
				});
				commit('SET_TASK_ITEM_COMMENTS', [
					...(loadMore ? state.taskItemComments : []),
					...resp.data.payload,
				]);
				commit('SET_TASK_ITEM_COMMENTS_COUNT', resp.data.paginator.totalCount);
				return resp;
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Komentáře se nepodařilo načíst.') }</p>`,
					type: 'error',
				});
			}
		},

		async addComment (_, { id, comment }: { id: number; comment: string }) {
			try {
				const resp = await api.post(`/task-item/${ id }/comment`, { comment });
				new TempMessage({
					html: `<p>${ t('Komentář byl přidán.') }</p>`,
					type: 'success',
				});
				return resp;
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Komentář se nepodařilo přidat.') }</p>`,
					type: 'error',
				});
			}
		},
	});
